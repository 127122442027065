import request from "@/api/request"

// 添加简历
export function addResume(data) {
  return request({
    method: 'post',
    url: '/job/jobResume/addResume',
    data:data
  })
}

// 查询简历
export function detailResume(data) {
  return request({
    method: 'get',
    // url: '/job/jobResume/detailResume',
    url: '/job/oauth/jobResume/detailResume',
    params:data
  })
}

// 编辑简历
export function editResume(data) {
  return request({
    method: 'post',
    url: '/job/jobResume/editResume',
    data:data
  })
}

// 编辑认证信息
export function editJobAuthUser(data) {
  return request({
    method: 'post',
    url: '/jobUser/jobAuthUser/edit',
    data:data
  })
}

// 编辑认证信息
export function editJobUser(data) {
  return request({
    method: 'post',
    url: '/jobUser/jobUser/edit',
    data:data
  })
}

// 我的面试
export function jobApply(data) {
  return request({
    method: 'get',
    url: '/job/jobApply/jobApplyPage',
    params:data
  })
}



// 订单列表
export function jobOrderPage(data) {
  return request({
    method: 'get',
    url: '/main/jobOrder/page',
    params:data
  })
}


// 提交建议反馈
export function jobFeedbackAdd(data) {
  return request({
    method: 'post',
    url: '/main/oauth/jobFeedbackAdd',
    data:data
  })
}

// 同意拒绝面试
export function jobApplyInv(data) {
  return request({
    method: 'post',
    url: '/job/jobApply/jobApplyInvit',
    data:data
  })
}

//我的课程
export function courseApplyPage(params)  {
  return request({
    method: 'get',
    url: '/course/jcCourseApply/applyPage',
    params:params
    })
}


// 查询培训报名
export function trainingApplyPage (parameter) {
  return request({
    url: '/course/jcTrainingOrg/applyPage',
    method: 'get',
    params: parameter
  })
}


// 个人会员信息
export function selectEquity(data)  {
  return request({
    method: 'get',
    url: '/job/jobVipGroupOrg/selectEquity',
    params:data
  })
}
// 购买会员 / 购买服务权益
export function jobOrderItem(data)  {
  return request({
    method: 'post',
    url: `/job/jobOrder/saveOrder`,
    data:data
  })
}

//取消订单
export function jobOrderIte(data)  {
  return request({
    method: 'post',
    url: `/job/jobOrder/edit`,
    data:data
  })
}

//个人权益消费记录
export function page(params)  {
  return request({
    method: 'get',
    url: '/main/jobOwnerEquityLogs/page',
    params:params
  })
}

//消息中心
// export  function  getNoticeList(params)  {
//   return  request({
//     method:'get',
//     url:'/main/jobInform/page',
//     params:params
//   })
// }

//消费记录
export  function  jobOwnerEquityLogs(params)  {
  return  request({
    method:'get',
    url:'/main/jobOwnerEquityLogs/page',
    params:params
  })
}
//权益类型列表
export  function  jobVipEquity(params)  {
  return  request({
    method:'get',
    url:'/main/jobVipEquity/list',
    params:params
  })
}

// 求助反馈表列表
// createUser
 export function jobFeedbackPage(params) {
  return request({
    url: '/job/jobFeedback/page',
    method: 'get',
    params:params
  })
}
// 权益使用列表
export function jobAdditionalConfigList (parameter) {
  return request({
    url: '/job/jobAdditionalConfig/list',
    method: 'get',
    params: parameter
  })
}

// 权益使用提交
export function jobAdditional (parameter) {
  return request({
    url: '/job/jobAdditional/add',
    method: 'post',
    data: parameter
  })
}
