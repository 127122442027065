<template>
  <div class="main">
    <div v-if="hasResume">
      <el-empty description="暂无简历">
        <el-button type="primary" @click="addResume">添加简历</el-button>
      </el-empty>
    </div>
    <div v-else class="itemBox">
      <div class="itemName">
        <el-divider>
          <div class="dividerFont">
            <span>简历管理</span>
            <div
              style="width: 60px; height: 2px; background-color: #029aff"
            ></div>
          </div>
        </el-divider>
      </div>

      <div class="essentialInformation">
        <div class="title">
          <div class="blueBgc">
            <i
              class="iconfont icon-jibenxinxi1"
              style="font-size: 20px; color: #fff"
            ></i>
          </div>
          <b>基本信息</b>
          <el-divider></el-divider>
        </div>
        <div class="formItem">
          <div class="personalInfo">
            <b>{{ resume.name }}</b>
            <ul>
              <li>
                <span class="fontColor6">出生年月</span>
                <span class="fontColor6">{{
                  resume.wasBornDate
                    ? resume.wasBornDate.substring(
                        0,
                        resume.wasBornDate.indexOf(' ')
                      )
                    : ''
                }}</span>
              </li>
              <li>
                <span class="fontColor6">民族</span>
                <span class="fontColor6">{{ resume.nation }}</span>
              </li>
              <li>
                <span class="fontColor6">年龄</span>
                <span class="fontColor6">{{ resume.age }}</span>
              </li>
              <li>
                <span class="fontColor6">联系方式</span>
                <span class="fontColor6">{{ resume.tel }}</span>
              </li>
              <li>
                <span class="fontColor6">学历</span>
                <span class="fontColor6">{{
                  resume.edu ? showEdu(resume.edu) : ''
                }}</span>
              </li>
              <li>
                <span class="fontColor6">邮箱</span>
                <span class="fontColor6">{{ resume.email }}</span>
              </li>
              <li>
                <span class="fontColor6">毕业时间</span>
                <span class="fontColor6">{{
                  resume.finishSchoolTime
                    ? resume.finishSchoolTime.substring(
                        0,
                        resume.finishSchoolTime.indexOf(' ')
                      )
                    : ''
                }}</span>
              </li>
              <li>
                <span class="fontColor6">毕业学校</span>
                <span class="fontColor6">{{ resume.school }}</span>
              </li>
              <li>
                <span class="fontColor6">工作经验</span>
                <span class="fontColor6">{{ getExp(resume.exp) }}</span>
              </li>
              <li>
                <span class="fontColor6">在职状态</span>
                <span class="fontColor6">{{
                  getWorkingState(resume.workingState)
                }}</span>
              </li>
            </ul>
          </div>
          <div class="photo">
            <img class="photo" :src="avatar" alt="" v-if="avatar" />
            <img
              class="photo"
              src="@/assets/personCenter/resumeManagement/null.png"
              alt=""
              v-else
            />
            <div class="editBtn fontColor6" @click="edit">编辑</div>
          </div>
        </div>
      </div>

      <div class="jobSearchIntention">
        <div class="title">
          <div class="blueBgc">
            <i
              class="iconfont icon-lingdaitie2"
              style="font-size: 20px; color: #fff"
            ></i>
          </div>
          <b>求职意向</b>
          <el-divider></el-divider>
        </div>
        <div class="formItem">
          <div class="jobSearchIntentionInfo">
            <div class="infoItem fontColor6">
              <i class="iconfont icon-zhiye1" style="font-size: 20px"></i>
              <span>{{ resume.expectPost }}</span>
            </div>
            <el-divider direction="vertical"></el-divider>
            <div class="infoItem fontColor6">
              <i class="iconfont icon-xinzi" style="font-size: 18px"></i>
              <span>{{ getPay(resume.expectSalary) }}</span>
            </div>
            <el-divider direction="vertical"></el-divider>
            <div class="infoItem fontColor6">
              <i class="iconfont icon-hangye" style="font-size: 20px"></i>
              <span>{{
                showTradeType(this.tradeTypeList, resume.expectIndustry)
              }}</span>
            </div>
            <el-divider direction="vertical"></el-divider>
            <div class="infoItem fontColor6">
              <i class="iconfont icon-31dingwei" style="font-size: 20px"></i>
              <!-- <span>{{ resume.city }}</span> -->
              <!-- <span>{{ getCityForId(resume.city) }}</span> -->
              <span>{{ resume.city }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="workExperience">
        <div class="title">
          <div class="blueBgc">
            <i
              class="iconfont icon-xiangmujingli-02"
              style="font-size: 20px; color: #fff"
            ></i>
          </div>
          <b>工作经历</b>
          <el-divider></el-divider>
        </div>
        <div
          class="formItem"
          v-for="(item, index) in workExperience"
          :key="index"
        >
          <div class="formItemLeft">
            <p>{{ item.postName }}</p>
            <span class="fontColor6 fontSize14"
              >{{
                item.btime
                  ? item.btime.substring(0, item.btime.lastIndexOf('-'))
                  : ''
              }}{{ item.btime ? ' - ' : ''
              }}{{
                item.ntime
                  ? item.ntime.substring(0, item.ntime.lastIndexOf('-'))
                  : ''
              }}</span
            >
          </div>
          <div class="formItemRight">
            <p class="companyName">{{ item.title }}</p>
            <p class="workInfo fontColor6 fontSize14">{{ item.content }}</p>
          </div>
        </div>
      </div>

      <div class="projectExperience">
        <div class="title">
          <div class="blueBgc">
            <i
              class="iconfont icon-jiaoyu1"
              style="font-size: 20px; color: #fff"
            ></i>
          </div>
          <b>项目经历</b>
          <el-divider></el-divider>
        </div>
        <div
          class="formItem"
          v-for="(item, index) in projectExperience"
          :key="index"
        >
          <p style="font-size: 18px">{{ item.title }}</p>
          <p class="fontSize14">
            工作时间：{{
              item.btime
                ? item.btime.substring(0, item.btime.lastIndexOf('-'))
                : ''
            }}{{ item.btime ? ' - ' : ''
            }}{{
              item.ntime
                ? item.ntime.substring(0, item.ntime.lastIndexOf('-'))
                : ''
            }}
          </p>
          <p>
            工作内容：<span class="fontColor6 fontSize14">{{
              item.content
            }}</span>
          </p>
        </div>
      </div>

      <div class="educationalExperience">
        <div class="title">
          <div class="blueBgc">
            <i
              class="iconfont icon-jiaoyu1"
              style="font-size: 20px; color: #fff"
            ></i>
          </div>
          <b>教育经历</b>
          <el-divider></el-divider>
        </div>
        <div
          class="formItem"
          v-for="(item, index) in educationalExperience"
          :key="index"
        >
          <div class="formItemLeft">
            <p>{{ item.specialty }}</p>
            <span class="fontColor6 fontSize14"
              >{{
                item.btime
                  ? item.btime.substring(0, item.btime.lastIndexOf('-'))
                  : ''
              }}{{ item.btime ? ' - ' : ''
              }}{{
                item.ntime
                  ? item.ntime.substring(0, item.ntime.lastIndexOf('-'))
                  : ''
              }}</span
            >
          </div>
          <div class="formItemRight">
            <p class="schoolName">{{ item.title }}</p>
            <p class="workInfo fontColor6 fontSize14">{{ item.content }}</p>
          </div>
        </div>
      </div>

      <div class="trainingExperience">
        <div class="title">
          <div class="blueBgc">
            <i
              class="iconfont icon-jiaoyu1"
              style="font-size: 20px; color: #fff"
            ></i>
          </div>
          <b>培训经历</b>
          <el-divider></el-divider>
        </div>
        <div
          class="formItem"
          v-for="(item, index) in trainingExperience"
          :key="index"
        >
          <p style="font-size: 18px">{{ item.title }}</p>
          <p class="fontColor6 fontSize14">
            {{
              item.btime
                ? item.btime.substring(0, item.btime.lastIndexOf('-'))
                : ''
            }}{{ item.btime ? ' - ' : ''
            }}{{
              item.ntime
                ? item.ntime.substring(0, item.ntime.lastIndexOf('-'))
                : ''
            }}
          </p>
          <p class="fontColor6 fontSize14">{{ item.content }}</p>
        </div>
      </div>

      <div class="selfEvaluation">
        <div class="title">
          <div class="blueBgc">
            <i
              class="iconfont icon-pingjia"
              style="font-size: 20px; color: #fff"
            ></i>
          </div>
          <b>自我评价</b>
          <el-divider></el-divider>
        </div>
        <div class="formItem">
          <span class="fontColor6 fontSize14">{{ resume.myIntro }}</span>
        </div>
      </div>

      <div class="skillCertificate">
        <div class="title">
          <div class="blueBgc">
            <i
              class="iconfont icon-pingjia"
              style="font-size: 20px; color: #fff"
            ></i>
          </div>
          <b>技能证书</b>
          <el-divider></el-divider>
        </div>
        <div class="formItem">
          <div class="imgBox">
            <span>学历证书</span>
            <img
              v-if="certificates.degree_certificate"
              :src="$imgUrl(certificates.degree_certificate)"
              :onerror="$global.srcImgUrl"
              alt=""
            />
            <img v-else src="@/assets/img/null.png" alt="" />
          </div>
          <div class="imgBox">
            <span>资格证书</span>
            <img
              v-if="certificates.qualification_certificate"
              :src="
                $imgUrl(certificates.qualification_certificate)
              "
              :onerror="$global.srcImgUrl"
              alt=""
            />
            <img v-else src="@/assets/img/null.png" alt="" />
          </div>
          <div class="imgBox">
            <span>职称证书</span>
            <img
              v-if="certificates.title_certificate"
              :src="$imgUrl(certificates.title_certificate)"
              :onerror="$global.srcImgUrl"
              alt=""
            />
            <img v-else src="@/assets/img/null.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      :visible="dialogVisible"
      top="30vh"
      width="30%"
      @close="dialogVisible = false"
    >
      <div
        slot="title"
        style="border-bottom: 1px solid #ccc; padding-bottom: 10px"
      >
        请选您之前工作的岗位类型
      </div>
      <div class="dialogBox">
        <div class="imgBox" @click="chooseIdentity(2)">
          <img src="@/assets/personCenter/technology.png" alt="" />
          <span>技术岗位</span>
        </div>
        <div class="imgBox" @click="chooseIdentity(1)">
          <img src="@/assets/personCenter/administration.png" alt="" />
          <span>管理岗位</span>
        </div>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span> -->
    </el-dialog>
  </div>
</template>

<script>
import FuYong from '../../../components/footer/footers.vue'
import { detailResume } from '@/api/personalCenter/personalCenter'
import { preview } from '@/api/certification/certification'
import { jobPostType } from '@/api/position/position'
import { getJobUserAuth } from '@/api/jobInformation/jobInformation'
export default {
  name: '',
  data() {
    return {
      imgUrl: '',
      dict: [], //字典
      jobEdu: [], //学历列表
      exp: [], //工作经验
      jobPay: [], //薪资
      workingState: [], //在职状态
      tradeTypeList: [], //行业列表

      // 个人简历
      resume: {},
      avatar: '', //头像
      hasResume: true, //判断是否有简历
      workExperience: [], //工作经历
      educationalExperience: [], //教育经历
      projectExperience: [], //项目经历
      trainingExperience: [], //培训经历
      dialogVisible: false, //对话框开关
      jobUserType: '', //简历类型
      certificates: {}, //证书信息
      cityLit: [], //城市列表
    }
  },
  watch: {},
  props: {},
  components: {
    FuYong,
  },
  computed: {},
  created() {
    this.dict = JSON.parse(localStorage.getItem('dict'))
    this.jobPay = this.dict.filter(i => i.code == 'job_pay')[0].children
    this.jobEdu = this.dict.filter(i => i.code == 'job_edu')[0].children
    this.exp = this.dict.filter(i => i.code == 'job_exp')[0].children
    this.workingState = this.dict.filter(
      i => i.code == 'job_resume_working_state'
    )[0].children
    this.cityLit = JSON.parse(localStorage.getItem('cityList'))
    this.getDetailResume()
    this.getJobTradeType()
    this.getJobUserAuthForId()
  },
  mounted() {},
  methods: {
    getPay(code) {
      return this.jobPay.filter(i => i.code == code)[0].name
    },
    // getCityForId(ids) {
    //   // let idsArr = JSON.parse(ids)
    //   // let id = idsArr[idsArr.length - 1]
    //   // return this.cityLit.filter(i => i.CITY_CODE == id)[0].SHORT_NAME
    //   console.log(ids);
    //   console.log(this.cityLit);
    //   console.log(this.cityLit.filter(i => i.CITY_CODE == ids));
    // },
    // 查询证书
    getJobUserAuthForId() {
      getJobUserAuth().then(res => {
        if (res.data) {
          this.certificates = res.data
        }
      })
    },
    // 选择简历类型
    chooseIdentity(num) {
      // let imgBoxs = document.getElementsByClassName('imgBox')
      if (num == 1) {
        this.jobUserType = 1
        // imgBoxs[1].classList.remove('jobUserType')
        // imgBoxs[0].classList.add('jobUserType')
      } else if (num == 2) {
        this.jobUserType = 2
        // imgBoxs[0].classList.remove('jobUserType')
        // imgBoxs[1].classList.add('jobUserType')
      }
      this.$router.push({
        query: { jobUserType: this.jobUserType },
        path: 'editResumeManagement',
      })
    },
    showTradeType(list, id) {
      var title = ''
      for (let i = 0; i < list.length; i++) {
        if (list[i].id == id) {
          title = list[i].title
          break
        } else if (list[i].children && list[i].children.length > 0) {
          title = this.showTradeType(list[i].children, id)
          if (title) return title
        }
      }
      console.log(title)
      return title
    },
    //行业类型
    getJobTradeType() {
      jobPostType({ code: 'trade_type' }).then(res => {
        this.tradeTypeList = res.data[0].children
      })
    },
    // 显示在职状态
    getWorkingState(val) {
      return this.workingState.filter(i => i.code == val)[0].name
    },
    // 显示工作经验
    getExp(val) {
      return this.exp.filter(i => i.code == val)[0].name
    },
    // 显示学历
    showEdu(val) {
      return this.jobEdu.filter(i => i.code == val)[0].name
    },
    // 添加简历
    addResume() {
      // this.$router.push({ path: '/editResumeManagement' })
      this.dialogVisible = true
    },
    //编辑简历
    edit() {
      this.$router.push({ path: 'editResumeManagement' })
    },
    // 获取简历
    getDetailResume() {
      let id = localStorage.getItem('userId')

      detailResume({ JobUserId: id })
        .then(res => {
          console.log(res, '获取简历')
          if (res.data == null) {
            // 新用户没有简历就去添加
            this.hasResume = true
          } else {
            this.hasResume = false
            this.resume = res.data
            this.resume.city = this.cityLit.filter(
              i => i.CITY_CODE == this.resume.city
            )[0].SHORT_NAME

            console.log(this.resume)
            let items = this.resume.items
            this.jobUserType = this.resume.jobUserType
            items.forEach(item => {
              if (item.itemType === '1') {
                // 工作经历
                this.workExperience.push(item)
              } else if (item.itemType === '2') {
                // 教育经历
                this.educationalExperience.push(item)
              } else if (item.itemType === '3') {
                // 项目经历
                this.projectExperience.push(item)
              } else if (item.itemType === '4') {
                // 培训经历
                this.trainingExperience.push(item)
              }
            })
            if (res.data.avatar) {
              // preview({ id: res.data.avatar }).then(res => {
              //   let blob = new Blob([res]) // 返回的文件流数据
              //   let url = window.URL.createObjectURL(blob) // 将他转化为路径
              //   this.avatar = url
              // })
              this.avatar = res.data.avatar
            } else {
              this.avatar = ''
            }
          }
          console.log(this.hasResume)
        })
        .catch(err => {
          this.resume = null
        })
    },
  },
  filters: {},
}
</script>

<style scoped lang="scss">
.fontColor6 {
  color: #666;
}
.fontSize14 {
  font-size: 14px;
}
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
p {
  margin: 0;
  padding: 0;
}
.itemName {
  padding: 0 6px 0 46px;
  padding-bottom: 22px;
  .dividerFont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 50px;
    span {
      font-size: 24px;
      margin-bottom: 6px;
    }
  }
}
.itemBox {
  background-color: #fff;
  // padding: 20px 40px;
  padding: 20px 80px 30px 40px;
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  .blueBgc {
    width: 32px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    background-color: #3f9efc;
    border-radius: 50%;
    margin-right: 20px;
  }
  b {
    font-size: 20px;
  }
  .el-divider {
    flex: 1;
    margin-left: 20px;
  }
}

.formItem {
  margin-left: 50px;
  margin-bottom: 30px;
}

.essentialInformation {
  .formItem {
    display: flex;
    .personalInfo {
      flex: 1;
      b {
        font-size: 28px;
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;
        li {
          padding: 10px 0;
          width: 50%;
          span:nth-child(1) {
            display: inline-block;
            width: 70px;
            text-align-last: justify;
            position: relative;
            margin-right: 20px;
          }
          span:nth-child(1)::after {
            position: absolute;
            content: ':';
            right: -6px;
          }
        }
      }
    }
    .photo {
      width: 150px;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 100%;
        height: 150px;
        object-fit: cover;
      }
      .editBtn {
        width: 58px;
        height: 24px;
        border: 1px solid #666;
        border-radius: 4px;
        text-align: center;
        line-height: 24px;
        margin-top: 20px;
        cursor: pointer;
      }
    }
  }
}

.jobSearchIntentionInfo {
  display: flex;
  align-items: center;
  .infoItem {
    display: flex;
    align-items: center;
    i {
      margin-right: 10px;
    }
  }
  .el-divider {
    margin: 0 20px;
  }
}

.workExperience,
.educationalExperience {
  .formItem {
    display: flex;
    padding-bottom: 20px;
    .formItemLeft {
      width: 190px;
      p {
        margin-bottom: 10px;
        font-size: 18px;
      }
    }
    .formItemRight {
      flex: 1;
      .companyName,
      .schoolName {
        font-size: 18px;
        margin-bottom: 10px;
      }
    }
  }
  .formItem:nth-last-child(1) {
    padding-bottom: 0;
  }
}

.trainingExperience,
.projectExperience {
  .formItem {
    padding-bottom: 20px;
    p {
      display: block;
      margin-bottom: 20px;
    }
  }
}

.skillCertificate {
  .formItem {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    .imgBox {
      display: flex;
      flex-direction: column;
      margin-right: 20px;
      margin-bottom: 10px;
      span {
        font-size: 14px;
        color: #666;
      }
      img {
        width: 140px;
        height: 88px;
        object-fit: contain;
        margin-top: 10px;
      }
    }
  }
}

.dialogBox {
  height: 250px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .imgBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    img {
      width: 110px;
      height: 110px;
    }
    span {
      font-size: 20px;
      margin-top: 20px;
    }
  }
}
// .jobUserType {
//   border: 1px solid #1592ea;
// }
</style>
