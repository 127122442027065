<template>
  <div>
    <!-- 底部 -->
    <div class="dibu-c">
      <div class="dibu-boss">
        <div class="zi">
          <div class="zi1">联系我们</div>
          <div class="zi2">联系地址：武汉市硚口区汉口解放大道213号</div>
          <div class="zi2">联系电话：83628152</div>
          <div class="zi2">联系邮箱：chutiankechuang@163.com</div>
        </div>
        <!-- 二维码 -->
        <div class="op-box">
          <div class="op">
            <div class="bit">关注公众号</div>
            <!-- <img src="../../img/联图二维码 1@3x.png" alt="" /> -->
            <img src="http://121.36.46.11:9000/app/uniapp-xcx.jpg" alt="" />
          </div>
          <div class="op ol">
            <div class="bit">App下载</div>
            <div class="app-img">
              <!-- <div>
                <span style="font-size:18px; color: #fff">ios</span>
                <img style="margin-right: 10px;" src="@/assets/img/appDown.jpg" alt="" />
              </div> -->
              <div>
                <!-- <span style="font-size:18px; color: #fff">android</span> -->
                <img src="http://121.36.46.11:9000/app/android.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dibu-xian"></div>

      <div class="yuming" style="color:#fff" >
        Copyright © 2022 湖北楚天科创科技投资发展有限公司 All Rights Reserved
        | <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" style="color:#fff" >鄂ICP备2022015209号-1</a>
      <!-- |<a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=2022015209" target="_blank" style="color:#fff" >鄂ICP备2022015209号-2</a> -->
|
          <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=2022015209" style="color:#fff" >
          <img src="../../img/gongan.png" > <span>鄂公网安备 42010402001538号</span></a>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
}
</script>

<style scoped lang="scss">
.dibu-c {
  // width: 1920px;
  // background: url(../../img/footer.png) repeat;
  // background-size: 100% 100%;
  background-color: #000000;
  opacity: 0.6;
  width: 100%;
  // height: 310px;
  height: 210px;
  // background: rgba(32, 50, 102, 1);
  // padding-top: 64px;
  overflow: hidden;
  .dibu-boss {
    display: flex;
    justify-content: space-between;
    width: 1300px;
    height: 130px;
    margin: 20px auto;
    .zi {
      margin-left: 50px;
      color: rgba(255, 255, 255, 1);
      font-size: 14px;
      .zi1 {
        color: rgba(255, 255, 255, 1);
        font-size: 18px;
      }
      .zi2 {
        margin-top: 20px;
      }
    }
  }
  .op-box {
    // margin-left: 721px;
    display: flex;
  }
  .dibu-xian {
    margin: 0 auto;
    width: 1200px;
    height: 0px;
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
  .ol {
    margin-left: 35px;
  }
  .op {
    .bit {
      color: rgba(255, 255, 255, 1);
      font-size: 18px;
      margin-bottom: 25px;
      text-align: center;
    }
    img {
      display: block;
      width: 88px;
      height: 88px;
    }
  }
}
.yuming {
  width: 830px;
  // width: 1000px;
  height: 15px;
  color: rgba(154, 161, 179, 1);
  font-size: 12px;
  line-height: 15px;
  margin: 10px auto;
}
.app-img{
  // margin-top: -25px;
  display: flex;
  text-align: center;
}
</style>
